.container {
  z-index: 100;
  background-color: white;
  grid-area: 1 / 1;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.category {
  margin-bottom: 0;
}

.content {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.section {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: bold;
}

.tags {
  margin-top: 1em;
}

.tag {
  background-color: aliceblue;
  margin-right: 1em;
  padding: 0 1em;
  border: 1px solid black;
  border-radius: 1em;
}

.back {
  width: 100%;
  display: flex;
}

.button {
  flex: 1;
  appearance: none;
  border: 0;
  font-size: inherit;
  padding: 0;
  margin: 0;
}

.button {
  background-color: hsl(182deg 94% 20%);
  padding: 1em;
  border-radius: 4px;
  color: white;
}

.banner {
  overflow: hidden;
  display: flex;
  border-radius: 4px;
}

.banner > img {
  max-width: 100%;
}