.container {
  background-color: white;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.banner {
  background-color: hsl(182deg 94% 40%);
  /* background-color: hsl(182deg 94% 20%); */
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
  color: white;
}

.banner > p {
  margin-top: 0;
}
.form {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.content > input[type=text], 
.content > input[type=email], 
.content > input[type=password] {
  padding: 0.5em 1em;
  appearance: none;
  border: 1px solid hsl(0, 0%, 90%);
}

.content > input {
  margin-bottom: 0.5em;
}

.content > input:last-of-type {
  margin: 0;
}

.button {
  margin: 0;
  padding: 0;
  appearance: none;
  outline: none;
  display: flex;
  border: 0;
  font-size: inherit;
}

.button-content {
  background-color: hsl(182deg 94% 20%);
  padding: 0.5em 1em;
  height: 3em;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 4px;
  color: white;
}
