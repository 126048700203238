.container {
  margin-top: 1em;
  grid-area: 1 / 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 0.5em;
}

.container > div:last-of-type {
  margin-right: 0;
}

.item {
  overflow: hidden;
  background: hsl(182deg 94% 40%);
  position: relative;
  margin-right: 1em;
  width: 100%;
  height: 7em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.image {
  height: calc(100vw / 2);
}

@media only screen and (min-width: 768px) {
  .image {
    height: calc(768px / 2);
  }
}

.box {
  background-color: hsla(182deg 94% 40% / 0.6);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 0.9em;
  font-weight: bold;
  color: white;
}
