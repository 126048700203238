.container {
  margin-bottom: 0.5em;
  padding: 0.5em;
  display: flex;
  border-radius: 4px;
}

.icon {
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon > span:first-of-type {
  font-size: 1.2em;
  font-weight: bold;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}
