.container {
  display: flex;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.list {
}

.button {
  background-color: transparent;
  appearance: none;
  outline: none;
  border: 0;
  padding: 0;
  margin: 0 auto;
  font-size: inherit;
}

.button-content {
  width: 5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid hsl(182deg 94% 40%);
  border-radius: 1em;
}

.button-content > span {
  font-size: 0.9em;
}
