.container {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
}

.container > div:last-of-type {
  border-bottom: 0;
}

.step {
  margin-bottom: 0.5em;
  padding: 0.5em 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid hsl(0, 0%, 97%);
}

.odd {
  flex-direction: row-reverse;
}

.image {
  background-color: hsl(182deg 94% 40%);
  margin: 0 1em;
  min-width: 3em;
  height: 3em;
  flex: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

.text {
  display: flex;
  flex: 1;
}
