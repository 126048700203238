.container {
  display: flex;
  flex-direction: column;
}

.banner {
  padding: 0.5em;
  display: flex;
}

.content {
  overflow: scroll;
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.button {
  padding: 0.5em;
  border: 1px solid hsl(0, 0%, 50%);
  border-radius: 4px;
}
