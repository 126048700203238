.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.headline {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  font-weight: bold;
}

.select {
  margin: 0 auto;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1em;
  align-items: center;
}

.imagebox {
  background-color: #036063;
  width: 250px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 8px solid #036063;
}

.image {
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}

.button {
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.spacer {
  background-color: hsl(0, 0%, 80%);
  margin: 1em auto;
  width: 5em;
  height: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.qrbox {
  margin: 0 auto;
  width: 250px;
  display: flex;
  flex-direction: column;
}

.qrcode {
  width: 250px;
}

.qrfb {
  margin: 0 auto;
  font-size: 0.9em;
}

@media only screen and (min-width: 768px) {
  .select {
    display: grid;
    grid-auto-flow: column;
  }
}
