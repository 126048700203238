.container {
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > img {
  object-fit: contain;
  width: 275px;
}

.logo {
  background-color: darkgreen;
  width: 3em;
  height: 3em;
  border-radius: 50%;
}
