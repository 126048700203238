.container {
  margin-bottom: 1em;
  padding: 0.5em;
}

.styled {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.title {
}

.content {
}
